import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { pipe, catchError, throwError, UnaryFunction, Observable } from 'rxjs';
import { ToastsService } from '../toasts/toasts.service';

@Injectable({
  providedIn: 'root',
})
export class HubHttpErrorHelperService {
  constructor(private toastsService: ToastsService) {}

  displayErrorToast(): UnaryFunction<Observable<unknown>, Observable<unknown>> {
    return pipe(
      catchError((err: HttpErrorResponse) => {
        this.toastsService.show(this.getErrorFromStatus(err));
        return throwError(() => err);
      }),
    );
  }

  getErrorFromStatus(error: HttpErrorResponse): string {
    switch (error.status) {
      case 403:
        return 'Your license has expired. Please contact support to get a new license.';
      case 409:
        return "Conflict encountered. Please make sure you're using unique configuration.";
      default:
        return `Unexpected Error. ${
          error?.error?.description || error?.error?.detailedMessage || ''
        }`;
    }
  }
}
