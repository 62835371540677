import { orderBy } from 'lodash-es';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export interface SideItem {
  id: string;
  name: string;
  modifiedBy: string;
  modifiedAt: string;
}

@Component({
  selector: 'hub-side-selector',
  templateUrl: './hub-side-selector.component.html',
  styleUrls: ['./hub-side-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class HubSideSelectorComponent {
  @Input() set items(val: SideItem[] | null) {
    this._items = val;

    if (this._items) {
      this.sortItems();
      if (!this.activeItem && this.sortedItems.length > 0) {
        this.itemSelected.emit(this.sortedItems[0]);
      }
    }
  }

  @Input() activeItem?: SideItem;

  @Input() set sorting(sorting: string) {
    this._sorting = sorting;
    if (this._items) {
      this.sortItems();
    }
  }

  @Output() itemSelected = new EventEmitter<SideItem>();

  sortedItems: SideItem[] = [];

  private _sorting?: string;

  private _items?: SideItem[] | null = [];

  sortItems(): void {
    if (!this._sorting) {
      this.sortedItems = this._items || [];
      return;
    }
    const [key, direction] = this._sorting.split('_') as [keyof SideItem, 'asc' | 'desc'];
    this.sortedItems = orderBy(this._items, (value) => value[key], [direction]);
  }
}
