<div class="modal-header">
  <h3>Create New Webhook</h3>
</div>
<div class="modal-body">
  <app-webhook-form [value]="webhook" [activeStep]="'CREATE'"></app-webhook-form>
</div>

<div class="modal-footer hub-d-flex hub-justify-content-end">
  <button type="button" class="btn btn-primary-outline hub-mr-1" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button
    type="button"
    class="btn btn-primary"
    (click)="save()"
    [disabled]="webhookFormComponent && webhookFormComponent.form.invalid">
    Create Webhook
  </button>
</div>
