import { HubDestroyService } from '@core/services/hub-destroy/hub-destroy.service';
import { Webhook, WebhooksApiService } from '@core/services/webhooks-api/webhooks-api.service';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WebhookFormComponent } from '../webhook-form/webhook-form.component';
import { HubHttpErrorHelperService } from '@core/services/hub-http-error-helper/hub-http-error-helper.service';

@Component({
  selector: 'app-create-webhook-modal',
  templateUrl: './create-webhook-modal.component.html',
  styleUrls: ['./create-webhook-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HubDestroyService],
  standalone: false,
})
export class CreateWebhookModalComponent {
  webhook = this.getEmptyWebhook();

  @ViewChild(WebhookFormComponent) webhookFormComponent!: WebhookFormComponent;

  constructor(
    public activeModal: NgbActiveModal,
    private webhooksApiService: WebhooksApiService,
    private hubDestroyService: HubDestroyService,
    private hubHttpErrorHelperService: HubHttpErrorHelperService,
  ) {}

  save() {
    const rawValue = this.webhookFormComponent.form.getRawValue();

    this.webhooksApiService
      .create({
        ...rawValue,
        body: JSON.parse(rawValue.body || '{}'),
        params: {},
      } as Partial<Webhook>)
      .pipe(
        this.hubHttpErrorHelperService.displayErrorToast(),
        this.hubDestroyService.takeUntilDestroy(),
      )
      .subscribe(() => {
        this.activeModal.close();
      });
  }

  getEmptyWebhook(): Webhook {
    return {
      id: null,
      name: '',
      body: '',
      method: 'GET',
      eventName: '',
      headers: {
        'Content-Type': 'application/json',
      },
      description: '',
      endpoint: 'http://',
      queryParams: {},
    } as unknown as Webhook;
  }
}
