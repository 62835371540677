import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Observable } from 'rxjs';

export interface Webhook {
  id: string;
  eventName: string;
  name: string;
  description: string;
  method: string;
  endpoint: string;
  body: Record<string, unknown>;
  headers: Record<string, unknown>;
  queryParams: Record<string, unknown>;
  modifiedAt: string;
  modifiedBy: string;
  secretKey: string;
  useSecret: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class WebhooksApiService {
  private readonly baseUrl = `${environment.BACKEND_DOMAIN}/api/v1/webhooks`;

  constructor(private httpClient: HttpClient) {}

  all(): Observable<Webhook[]> {
    return this.httpClient.get<Webhook[]>(this.baseUrl);
  }

  getEventNames(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.baseUrl}/events/names`);
  }

  create(body: Partial<Webhook>): Observable<void> {
    return this.httpClient.post<void>(this.baseUrl, body);
  }

  delete(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${id}`);
  }

  update(body: Partial<Webhook>): Observable<void> {
    return this.httpClient.put<void>(`${this.baseUrl}/${body.id}`, body);
  }

  toggleSecure(id: string, useSecret: boolean): Observable<void> {
    return this.httpClient.post<void>(
      `${this.baseUrl}/${id}/secret/use`,
      {},
      { params: { enable: useSecret } },
    );
  }

  generateSecret(id: string): Observable<{ secretKey: string }> {
    return this.httpClient.post<{ secretKey: string }>(`${this.baseUrl}/${id}/secret`, {});
  }
}
