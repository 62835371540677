import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { shareReplay } from 'rxjs/operators';
import { SideItem } from '@shared/components/hub-side-selector/hub-side-selector.component';
import { Observable, startWith, Subject, switchMap } from 'rxjs';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ActionModalHelperService } from '@core/services/action-modal-helper/action-modal-helper.service';
import { Webhook, WebhooksApiService } from '@core/services/webhooks-api/webhooks-api.service';
import { WebhookFormComponent } from './webhook-form/webhook-form.component';
import { HUB_SORT_BAR_CUSTOM_SIDE_SELECTOR } from '@shared/components/hub-sort-bar/hub-sort-bar';
import { HubHttpErrorHelperService } from '@core/services/hub-http-error-helper/hub-http-error-helper.service';
import { ToastsService } from '@core/services/toasts/toasts.service';
import { CreateWebhookModalComponent } from './create-webhook-modal/create-webhook-modal.component';

@Component({
  selector: 'app-webhooks-tab',
  templateUrl: './webhooks-tab.component.html',
  styleUrls: ['./webhooks-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class WebhooksTabComponent {
  sorting = 'name_asc';

  sortConfig = HUB_SORT_BAR_CUSTOM_SIDE_SELECTOR;

  refreshListSubject = new Subject<void>();

  activeWebhook?: Webhook;

  originalWebhook?: Webhook;

  refreshList$ = this.refreshListSubject.asObservable().pipe(startWith(undefined));

  webhooks$: Observable<Webhook[]> = this.refreshList$.pipe(
    switchMap(() => this.webhooksApiService.all()),
    shareReplay(1),
  );

  @ViewChild(WebhookFormComponent) webhookFormComponent!: WebhookFormComponent;

  constructor(
    private ngbModal: NgbModal,
    private toastsService: ToastsService,
    private webhooksApiService: WebhooksApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private actionModalHelperService: ActionModalHelperService,
    private hubHttpErrorHelperService: HubHttpErrorHelperService,
  ) {}

  cancel(): void {
    this.activeWebhook = this.originalWebhook
      ? JSON.parse(JSON.stringify(this.originalWebhook))
      : undefined;
    this.webhookFormComponent.form.disable();
  }

  select(webhook?: SideItem): void {
    this.activeWebhook = webhook as unknown as Webhook;
    this.originalWebhook = webhook ? JSON.parse(JSON.stringify(webhook)) : undefined;
  }

  edit(): void {
    this.webhookFormComponent.form.enable();
  }

  remove(): void {
    if (!this.activeWebhook) {
      return;
    }
    const webhook = this.activeWebhook;
    const remove$ = this.actionModalHelperService.confirmRemoval(webhook.name, 'Webhook');
    remove$
      .pipe(
        switchMap(() => this.webhooksApiService.delete(webhook.id)),
        this.hubHttpErrorHelperService.displayErrorToast(),
      )
      .subscribe(() => {
        this.toastsService.show('Webhook has been removed');
        this.activeWebhook = undefined;
        this.originalWebhook = undefined;
        this.changeDetectorRef.detectChanges();
        this.refreshListSubject.next();
      });
  }

  update(): void {
    const rawValue = this.webhookFormComponent.form.getRawValue();

    this.webhooksApiService
      .update({
        ...rawValue,
        body: JSON.parse(rawValue.body || '{}'),
        params: {},
      } as Partial<Webhook>)
      .pipe(
        switchMap(() => {
          if (rawValue.id && rawValue.useSecret != null) {
            return this.webhooksApiService.toggleSecure(rawValue.id, rawValue.useSecret);
          }
          throw Error('invalid webhook - failed to secure webhook');
        }),
        this.hubHttpErrorHelperService.displayErrorToast(),
      )
      .subscribe(() => {
        this.toastsService.show('Webhook has been saved');
        this.refreshListSubject.next();
        this.webhookFormComponent.form.disable();
      });
  }

  openModal(): void {
    const modal = this.ngbModal.open(CreateWebhookModalComponent, {
      centered: true,
    });

    modal.closed.subscribe(() => {
      this.refreshListSubject.next();
      this.toastsService.show('Webhook has been created');
    });
  }
}
