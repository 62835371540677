@if (sortedItems) {
  @for (item of sortedItems; track item.id) {
    <hub-button
      type="clean"
      class="item-btn hub-mb-1-5 hub-pr-0-5 hub-p-relative"
      [class.active]="activeItem?.id === item.id"
      customClass="hub-w-100 hub-py-0-25 hub-text-left"
      (clicked)="itemSelected.next(item)">
      <p class="hub-fw-bold hub-text-truncate hub-mb-0">{{ item.name }}</p>
      <p class="hub-fs-14 hub-text-message hub-text-truncate hub-mb-0">
        {{ item.modifiedBy }} | {{ item.modifiedAt | date: 'd MMM y' }}
      </p>
    </hub-button>
  }
}
