import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HubDestroyService } from '@core/services/hub-destroy/hub-destroy.service';
import { ToastsService } from '@core/services/toasts/toasts.service';
import { Webhook, WebhooksApiService } from '@core/services/webhooks-api/webhooks-api.service';
import { DropdownValue } from '@shared/components/hub-dropdown-form-field/hub-dropdown';
import { isValidJSONValidator } from '@shared/validators/json.validator';
import { map, Observable, of, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { UserState } from '@core/store/user/user.state';

export enum Tabs {
  BODY = 'body',
  HEADER = 'header',
  SECURITY = 'security',
  QUERY_PARAMS = 'query_params',
}

@Component({
  selector: 'app-webhook-form',
  templateUrl: './webhook-form.component.html',
  styleUrls: ['./webhook-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class WebhookFormComponent {
  @Input() activeStep: 'VIEW' | 'CREATE' = 'VIEW';

  form = new FormGroup({
    id: new FormControl<string | null>(''),
    name: new FormControl<string>('', [Validators.required]),
    endpoint: new FormControl<string>('http://'),
    body: new FormControl<string>('{}', [isValidJSONValidator]),
    method: new FormControl<string>('GET'),
    eventName: new FormControl<string>('', [Validators.required]),
    headers: new FormControl<Record<string, string | number | boolean>>({}),
    useSecret: new FormControl<boolean>(false),
    secretKey: new FormControl<string>(''),

    // not editable from UI, required for PUT update
    description: new FormControl<string>(''),
    queryParams: new FormControl<Record<string, string | number | boolean>>({}),
  });

  @Input() set value(webhook: Webhook | undefined | null) {
    if (!webhook) {
      return;
    }

    if (!webhook.id) {
      this.form.reset();
      this.form.enable();
      this.form.setValue(this.parseWebhook(webhook) as never);
    } else {
      this.form.disable();
      this.form.patchValue(this.parseWebhook(webhook) as never);
    }
  }

  methods = ['GET', 'POST', 'PUT', 'PATCH'].map(this.stringToDropdownValue);

  activeTabId: string = Tabs.BODY;

  tabs = Tabs;

  private readonly store = inject(Store);

  eventNameOptions$: Observable<DropdownValue[]> = this.store.selectSnapshot(UserState.isAdminUser)
    ? this.webhooksApiService
        .getEventNames()
        .pipe(map((eventNames) => eventNames.map(this.stringToDropdownValue)))
    : of([]);

  constructor(
    private webhooksApiService: WebhooksApiService,
    private readonly toastService: ToastsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private hubDestroyService: HubDestroyService,
  ) {}

  private stringToDropdownValue(label: string): DropdownValue {
    return { label, value: label };
  }

  private parseWebhook(webhook: Webhook): Omit<Webhook, 'body'> & { body: string } {
    return {
      ...webhook,
      useSecret: webhook.useSecret ? webhook.useSecret : false,
      secretKey: webhook.secretKey ? webhook.secretKey : '',
      body: webhook.body ? JSON.stringify(webhook.body, null, 2) : '{}',
    };
  }

  onGenerateSecret() {
    const id = this.form.get('id')?.value;
    if (id) {
      this.webhooksApiService
        .generateSecret(id)
        .pipe(
          catchError((err) => {
            this.toastService.show('Failed to generate secret Key');
            return throwError(() => err);
          }),
          takeUntil(this.hubDestroyService.destroy$),
        )
        .subscribe((res) => {
          this.form.patchValue({
            secretKey: res.secretKey,
          });
          this.changeDetectorRef.detectChanges();
          this.toastService.show('Generated secret key successfully');
        });
    }
  }
}
