@if (activeWebhook || (webhooks$ | async)?.length) {
  <div class="hub-d-flex">
    <h2>Webhooks</h2>
    <div class="hub-d-flex hub-align-items-center hub-ml-auto" *hubIsAdmin>
      @if (webhookForm.form.enabled) {
        <hub-button type="primaryOutline" [class]="'hub-mr-0-5'" (clicked)="cancel()">
          Cancel
        </hub-button>
      }
      @if (webhookForm.form.enabled) {
        <hub-button class="hub-mr-0-5" (clicked)="update()" [isDisabled]="webhookForm.form.invalid">
          Save
        </hub-button>
      }
      @if (webhookForm.form.disabled) {
        <hub-button
          type="primaryOutline"
          [class]="'hub-mr-0-5'"
          (clicked)="edit()"
          [icon]="'icon-edit'">
          Edit
        </hub-button>
      }
      @if (webhookForm.form.disabled) {
        <hub-button type="primary" (clicked)="openModal()"> Create a Webhook</hub-button>
      }
    </div>
  </div>
  <app-hub-sort-bar
    [activeSortType]="sorting"
    [config]="sortConfig"
    (sortChange)="sorting = $event"></app-hub-sort-bar>
  <div class="row">
    <hub-side-selector
      class="col-2 webhooks-list"
      [sorting]="sorting"
      [items]="webhooks$ | async"
      [activeItem]="activeWebhook"
      (itemSelected)="select($event)"></hub-side-selector>

    <div class="col-10 webhooks-list">
      <div class="webhooks-body">
        <app-webhook-form
          #webhookForm
          [value]="activeWebhook"
          [activeStep]="'VIEW'"></app-webhook-form>
        <div class="hub-text-center" *hubIsAdmin>
          @if (activeWebhook && (webhooks$ | async)?.length) {
            <button type="button" class="btn btn-link btn-text-danger hub-mt-1" (click)="remove()">
              Delete Webhook
            </button>
          }
        </div>
      </div>
    </div>
  </div>
} @else {
  <div
    class="hub-d-flex hub-flex-column empty-view-container hub-align-items-center hub-w-100 hub-mt-2-5">
    <hub-icon size="custom" [icon]="'icon-folder hub-fs-112 hub-mb-1-5'"></hub-icon>
    <h3 class="hub-fw-500 hub-ff-roboto hub-mb-1">There are no Webhooks yet.</h3>
    <p *hubIsNotAdmin class="hub-text-center">
      Please Contact your account admin to<br />add new Webhook.
    </p>
    <ng-container *hubIsAdmin>
      <div class="hub-d-flex hub-text-center">
        <p class="hub-mb-0">Please click</p>
        <button
          type="button"
          class="btn btn-link hub-py-0 hub-px-0-25 hub-border-0"
          (click)="openModal()">
          Create a Webhook
        </button>
        <p class="hub-mb-0">button to</p>
      </div>
      <p class="hub-text-center">create new Webhook.</p>
      <button type="button" class="btn btn-primary hub-mt-1-5" (click)="openModal()">
        Create a Webhook
      </button>
    </ng-container>
  </div>
}
