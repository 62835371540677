<form [formGroup]="form">
  <hub-input-form-control
    formControlName="name"
    label="Name"
    [maxLength]="60"
    [isRequired]="true"
    [hasMaxLengthCounter]="true"></hub-input-form-control>
  <hub-dropdown-form-field
    class="hub-d-block form-group"
    formControlName="method"
    label="Method"
    [options]="methods"></hub-dropdown-form-field>
  <hub-input-form-control
    formControlName="endpoint"
    label="URL"
    [maxLength]="120"
    [hasMaxLengthCounter]="true"></hub-input-form-control>
  <hub-dropdown-form-field
    class="hub-d-block form-group"
    formControlName="eventName"
    label="Triggered On"
    [required]="true"
    [options]="eventNameOptions$ | async"></hub-dropdown-form-field>

  <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs" [destroyOnHide]="false">
    <li [ngbNavItem]="tabs.BODY" class="hub-text-center">
      <a ngbNavLink class="tab-link">Body</a>
      <ng-template ngbNavContent>
        <label class="hub-fw-bolder">Body</label>
        <hub-json-monaco-form-control
          class="hub-d-block"
          label="Body"
          formControlName="body"></hub-json-monaco-form-control>
      </ng-template>
    </li>

    <li [ngbNavItem]="tabs.HEADER" class="hub-text-center">
      <a ngbNavLink class="tab-link">Header</a>
      <ng-template ngbNavContent>
        <app-hub-key-value-form-field
          class="hub-d-block form-group"
          label="Headers"
          formControlName="headers"></app-hub-key-value-form-field>
      </ng-template>
    </li>

    <li [ngbNavItem]="tabs.QUERY_PARAMS" class="hub-text-center">
      <a ngbNavLink class="tab-link">Query Params</a>
      <ng-template ngbNavContent>
        <app-hub-key-value-form-field
          class="hub-d-block form-group"
          label="Query Params"
          formControlName="queryParams"></app-hub-key-value-form-field>
      </ng-template>
    </li>
    @if (activeStep === 'VIEW') {
      <li [ngbNavItem]="tabs.SECURITY" class="hub-text-center" *hubIsAdmin>
        <a ngbNavLink class="tab-link">Security</a>
        <ng-template ngbNavContent>
          <div class="hub-d-flex hub-align-items-center hub-justify-content-between hub-mb-1">
            <hub-checkbox formControlName="useSecret" label="Secure Webhook"></hub-checkbox>
            @if (form.get('useSecret')?.value) {
              <button
                type="button"
                class="btn btn-primary"
                (click)="onGenerateSecret()"
                [disabled]="form.disabled">
                Generate New Secret
              </button>
            }
          </div>
          @if (form.get('useSecret')?.value) {
            <label class="secret hub-fs-16 hub-mb-0-25 hub-mt-0-5">Secret:</label>
            <p class="secret hub-fs-14 hub-border hub-rounded hub-p-0-25">
              {{ form.get('secretKey')?.value }}
            </p>
            @if (form.get('useSecret')?.value) {
              <button
                class="btn hub-p-0 hub-text-primary hub-d-flex hub-align-items-center"
                type="button"
                [hubCopyToClipboard]="form.get('secretKey')?.value || ''">
                <hub-icon [icon]="'hub-icon-primary icon-copy'"></hub-icon>
                Copy to clipboard
              </button>
            }
          }
        </ng-template>
      </li>
    }
  </ul>
  <div [ngbNavOutlet]="nav" class="hub-p-relative hub-pt-1-5"></div>
</form>
